import React from 'react';

import styles from './Loader.module.css';

function Loader() {
   return (
         <span className={styles.loader}></span>
   );
}

export default Loader;
